import React, { MouseEventHandler } from 'react'
import { ThemeColor } from '@emotion/react'
import { ChevronRightIcon } from '@platform-ui/icons/ChevronRightIcon'
import {
  Root,
  Content,
  InfoSlotTextSecondary,
  InfoSlotIcon,
  InfoSlotButton,
} from './InfoSlot.styled'

export type InfoSlotProps = Readonly<{
  className?: string
  buttonClick?: MouseEventHandler<HTMLButtonElement>
  text: string | React.ReactNode | React.ReactNode[]
  buttonText?: string
  icon?: React.ReactNode | React.ReactNode[]
  iconBgColor?: ThemeColor
  textColor?: ThemeColor
}>

export const InfoSlot = (props: InfoSlotProps) => {
  const { className, buttonClick, text, buttonText, icon, iconBgColor = 'icons/tertiary' } = props

  const handleButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    event.preventDefault()
    buttonClick(event)
  }

  return (
    <Root className={className}>
      <Content data-type="content">
        {icon && <InfoSlotIcon bgColor={iconBgColor}>{icon}</InfoSlotIcon>}
        <InfoSlotTextSecondary>{text}</InfoSlotTextSecondary>
      </Content>

      {buttonText && (
        <InfoSlotButton
          view="on-color"
          size="m"
          onClick={handleButtonClick}
          rightIcon={<ChevronRightIcon size="s12" />}
        >
          {buttonText}
        </InfoSlotButton>
      )}
    </Root>
  )
}
