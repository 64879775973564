import React, { SyntheticEvent } from 'react'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import Link from '@/next/link'
import { ProductPlate } from '@/components/ProductPlate'
import { ProductsModel } from '@/api/products/products'
import { PercentIcon } from '@platform-ui/icons/PercentIcon'
import { IconWrapper } from '@/components/IconWrapper'

export type ProductItemDepositProps = {
  className?: string
  product: ProductsModel.Deposit
  isEdit?: boolean
  isHidden?: boolean
  toggleHidden: (widgetId: ProductsModel.Deposit['settingWidgetId']) => void
}

export const ProductItemDeposit: React.FC<ProductItemDepositProps> = (props) => {
  const { product, isEdit = false, isHidden = false, toggleHidden } = props
  const context = useAppContext<AppContext>()

  const onToggleHidden = (e: SyntheticEvent) => {
    e.preventDefault()
    toggleHidden(product.settingWidgetId)
  }

  return (
    <Link href={context.routes.account(product.id)} passHref>
      <ProductPlate
        name={product.depositName}
        description={product.depositType}
        amount={product.runningBalance}
        currencyType={product.currencyType}
        icon={
          <IconWrapper backgroundColor="products/deposit" textColor="text/tertiary">
            <PercentIcon color="icons/shape" size="s12" />
          </IconWrapper>
        }
        isEdit={isEdit}
        isHidden={isHidden}
        toggleHidden={onToggleHidden}
        bgColor={product.bgColor}
        bgColorTheme={product.bgColorTheme}
      />
    </Link>
  )
}
