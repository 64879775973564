import { useProductsQuery } from '@/api/products/products'
import { useWidgetsSettingsQuery } from '@/api/products/widgetsSettings'
import { stockpilingProductsWithWigdetSettingsSelector } from '@/selectors/stockpilingProductsWithWigdetSettingsSelector'

export const useStockpilingProducts = () => {
  const deposits = useProductsQuery()

  const widgetsSettings = useWidgetsSettingsQuery()

  return stockpilingProductsWithWigdetSettingsSelector({
    deposits,
    widgetsSettings,
  })
}
