import React, { useEffect, useState } from 'react'
import { ProductList } from '@/components/ProductList'
import { ProductShimmer } from '../../components/ProductShimmer'
import { ProductItemCreditLoan } from '../ProductItemCreditLoan'
import { ApiStatus } from '@/core'
import { ProductError } from '@/components/ProductError'
import { observer } from 'mobx-react-lite'
import { useCreditProducts } from '../../hooks/useCreditProducts'
import { useShowProduct } from '../../hooks/useShowProduct'

export type ProductListCreditsProps = {
  className?: string
}

export const ProductListCredits = observer((props: ProductListCreditsProps): JSX.Element => {
  const { className } = props
  const { products, hiddenProductsIds, status, error, retry, hasAnyResult } = useCreditProducts()

  const [isLoading, setIsLoading] = useState(!hasAnyResult)

  const showProduct = useShowProduct()

  useEffect(() => {
    /**
     * Требуется показывать заголовок если данные ранее уже были получены
     */
    if (hasAnyResult) {
      setIsLoading(false)
    }
  }, [hasAnyResult])

  if (
    status === ApiStatus.Idle ||
    (error && error.type === 'IS_EMPTY_PRODUCTS') ||
    (status === ApiStatus.Fulfilled && hiddenProductsIds.length === 0)
  ) {
    return null
  }

  return (
    <ProductList title="Кредиты" isLoading={isLoading} className={className}>
      {hiddenProductsIds.map((productId, i) => {
        const product = products[productId]
        return (
          <ProductItemCreditLoan
            key={product.id}
            product={product}
            isEdit={true}
            isHidden={true}
            toggleHidden={() => showProduct(product.settingWidgetId)}
          />
        )
      })}
      {status === ApiStatus.Pending ? (
        <ProductShimmer />
      ) : (
        error && (
          <ProductError message="При получении кредитов произошла ошибка." retryClick={retry} />
        )
      )}
    </ProductList>
  )
})
