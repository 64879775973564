import React, { SyntheticEvent } from 'react'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import Link from '@/next/link'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { AlienCardsModel } from '@/api/products/alienCards/AlienCardsModel'
import { ProductPlate } from '@/components/ProductPlate'

export type ProductItemAlienCardProps = {
  className?: string
  product: AlienCardsModel.AlienCard
  isEdit?: boolean
  isHidden?: boolean
  toggleHidden: (widgetId: ClientProductsModel.CreditCard['settingWidgetId']) => void
}

export const ProductItemAlienCard: React.FC<ProductItemAlienCardProps> = (props) => {
  const { product, isEdit = false, isHidden = false, toggleHidden } = props
  const context = useAppContext<AppContext>()

  const onToggleHidden = (e: SyntheticEvent) => {
    e.preventDefault()
    toggleHidden(product.settingWidgetId)
  }

  return (
    <Link key={product.id} href={context.routes.card(product.id)} passHref>
      <ProductPlate
        bankIcon={product.bankLogo}
        description={product.description}
        lastFourCardDigits={product.lastFourCardDigits}
        name={product.bankShortName}
        paymentSystem={product.paymentSystem}
        bgColor={product.bgColor}
        bgColorTheme={product.bgColorTheme}
        isEdit={isEdit}
        isHidden={isHidden}
        toggleHidden={onToggleHidden}
      />
    </Link>
  )
}
