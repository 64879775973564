import { ThemeColor } from '@emotion/react'
import { Button } from '@platform-ui/components/Button'
import styled from '@emotion/styled'

export const Root = styled.div(({ theme }) => ({
  ...theme.typography['body/14'],
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: `0 16px`,
  color: theme.color['text/main'],
  backgroundColor: theme.color['surface/tertiary'],
  borderRadius: '20px',
  minHeight: '76px',
  alignItems: 'center',

  [`${theme.media.md}`]: {
    ...theme.typography['body/16'],
    minHeight: '90px',
    color: theme.color['text/main'],
    padding: `0 20px`,
  },
}))

export const Content = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '520px',
}))

export const RetryButton = styled(Button)(({ theme }) => ({}))
