import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { ProductListCredits } from './containers/ProductListCredits'
import { ProductListStockpiling } from './containers/ProductListStockpiling'
import { ProductListWallet } from './containers/ProductListWallet'

export const HiddenProductListsWrapper = styled.div(({ theme }) => ({
  marginTop: 40,

  [`${theme.media.md}`]: {
    marginTop: 24,
  },
  [`${theme.media.lg}`]: {
    marginTop: 32,
  },
}))

export type HiddenProductListsProps = Readonly<{
  className?: string
}>

export const HiddenProductLists = observer<HiddenProductListsProps>(({ className }) => {
  return (
    <HiddenProductListsWrapper>
      <ProductListWallet />
      <ProductListCredits />
      <ProductListStockpiling />
    </HiddenProductListsWrapper>
  )
})
