import { AppContext } from '@/models/AppContext'
import { useAppContext } from '@/core'
import { WidgetsSettingsModel } from '@/api/products/widgetsSettings'
import { setWidgetsSettingsRequest } from '@/api/products/setWidgetsSettings'

const Key = '/api/products/widgetsSettings'
export const useShowProduct = () => {
  const { queryClient } = useAppContext<AppContext>()

  const useShowProduct = (id: string) => {
    queryClient.setQueriesData<WidgetsSettingsModel.ResolveWithDataResponse>(Key, (oldData) => {
      const newData = {
        ...oldData,
        settings: {
          ...oldData.settings,
          [id]: { ...oldData.settings[id], isHidden: false },
        },
      }

      setWidgetsSettingsRequest({ settings: Object.values(newData.settings) })

      return newData
    })
  }

  return useShowProduct
}
