import React, { useEffect, useState } from 'react'
import { ProductList } from '../../components/ProductList'
import { ProductShimmer } from '../../components/ProductShimmer'
import { ProductsModel } from '@/api/products/products'
import { ProductItemDebitCard } from '../ProductItemDebitCard'
import { ProductItemCreditCard } from '../ProductItemCreditCard'
import { ProductItemInstalmentCard } from '../ProductItemInstalmentCard'
import { AlienCardsModel } from '@/api/products/alienCards'
import { ApiStatus } from '@/core'
import { ProductError } from '../../components/ProductError'
import { observer } from 'mobx-react-lite'
import { ProductItemAlienCard } from '../ProductItemAlienCard'
import { useWalletProducts } from '../../hooks/useWalletProducts'
import { useShowProduct } from '../../hooks/useShowProduct'

export type ProductListWalletProps = {
  className?: string
}

export const ProductListWallet = observer(({ className }: ProductListWalletProps): JSX.Element => {
  const { products, status, error, retry, hiddenProductsIds, hasAnyResult } = useWalletProducts()

  const [isLoading, setIsLoading] = useState(!hasAnyResult)

  const showProduct = useShowProduct()

  useEffect(() => {
    /**
     * Требуется показывать заголовок если данные ранее уже были получены
     */
    if (hasAnyResult) {
      setIsLoading(false)
    }
  }, [hasAnyResult])

  if (
    status === ApiStatus.Idle ||
    (error && error.type === 'IS_EMPTY_PRODUCTS') ||
    (status === ApiStatus.Fulfilled && hiddenProductsIds.length === 0)
  ) {
    return null
  }

  return (
    <ProductList title="Кошелёк" isLoading={isLoading} className={className}>
      {hiddenProductsIds.map((productId, i) => {
        const item = products[productId]
        if (item.productType === ProductsModel.ProductType.DebitCard) {
          return (
            <ProductItemDebitCard
              key={item.id}
              product={item}
              isEdit={true}
              isHidden={true}
              toggleHidden={() => showProduct(item.settingWidgetId)}
            />
          )
        }
        if (item.productType === ProductsModel.ProductType.CreditCard) {
          return (
            <ProductItemCreditCard
              key={item.id}
              product={item}
              isEdit={true}
              isHidden={true}
              toggleHidden={() => showProduct(item.settingWidgetId)}
            />
          )
        }

        if (item.productType === AlienCardsModel.ProductType.AlienCard) {
          return (
            <ProductItemAlienCard
              key={item.id}
              product={item}
              isEdit={true}
              isHidden={true}
              toggleHidden={() => showProduct(item.settingWidgetId)}
            />
          )
        }

        return (
          <ProductItemInstalmentCard
            key={item.id}
            product={item}
            isEdit={true}
            isHidden={true}
            toggleHidden={() => showProduct(item.settingWidgetId)}
          />
        )
      })}
      {status === ApiStatus.Pending ? (
        <ProductShimmer />
      ) : (
        error && (
          <ProductError message="При получении части карт произошла ошибка." retryClick={retry} />
        )
      )}
    </ProductList>
  )
})
