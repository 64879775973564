import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const ProductShimmer = styled(Shimmer)(() => ({
  marginTop: '12px',
  height: '88px',
  width: '100%',
  borderRadius: '20px',
  ':first-of-type': {
    marginTop: '0',
  },
}))
