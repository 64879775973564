import { ThemeColor } from '@emotion/react'
import { Button } from '@platform-ui/components/Button'
import styled from '@emotion/styled'

export const Root = styled.div(({ theme }) => ({
  ...theme.typography['body/14'],
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '12px',
  color: 'inherit',

  [`[data-type="content"]`]: {
    marginBottom: '12px',
  },

  [`${theme.media.md}`]: {
    ...theme.typography['body/16'],
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '16px',
    color: 'inherit',

    [`[data-type="content"]`]: {
      marginBottom: 0,
    },
  },
}))

export const Content = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '520px',
}))

export const InfoSlotTextSecondary = styled.p(({ theme }) => ({
  ...theme.typography['body/14'],
  color: 'inherit',

  [`${theme.media.md}`]: {
    ...theme.typography['body/16'],
  },
}))

export const InfoSlotIcon = styled.div<{ bgColor: ThemeColor }>(({ theme, bgColor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 'none',
  width: '24px',
  height: '24px',
  marginRight: '12px',
  fontSize: 0,
  borderRadius: '50%',
  background: theme.color[bgColor],
}))

export const InfoSlotButton = styled(Button)(() => ({
  whiteSpace: 'nowrap',
}))
