import React, { MouseEventHandler } from 'react'
import { Root, Content, RetryButton } from './styled'

export type ProductErrorProps = Readonly<{
  className?: string
  retryClick?: MouseEventHandler<HTMLButtonElement>
  message: string | React.ReactNode | React.ReactNode[]
}>

export const ProductError = (props: ProductErrorProps) => {
  const { className, retryClick, message = 'При получении продуктов произошла ошибка.' } = props

  return (
    <Root className={className}>
      <Content>{message}</Content>
      {retryClick && (
        <RetryButton view="ghost" onClick={retryClick} sizeSm={'m'} sizeMd={'l'}>
          Повторить
        </RetryButton>
      )}
    </Root>
  )
}
