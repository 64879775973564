import { useAlienCardsQuery } from '@/api/products/alienCards'
import { useProductsQuery } from '@/api/products/products'
import { useWidgetsSettingsQuery } from '@/api/products/widgetsSettings'
import { walletProductsWithWigdetSettingsSelector } from '@/selectors/walletProductsWithWigdetSettingsSelector'

export const useWalletProducts = () => {
  const products = useProductsQuery()

  const alienCards = useAlienCardsQuery()

  const widgetsSettings = useWidgetsSettingsQuery()

  return walletProductsWithWigdetSettingsSelector({
    products,
    alienCards,
    widgetsSettings,
  })
}
